import React, { useState } from "react"
import { navigate } from "gatsby"
import Photo from "../../images/consultor.png"
import client from "../../service/graphql"
import querys from "../../service/querys"

import {
  Container,
  Section,
  Header,
  Profile,
  User,
  Form,
  Label,
  Input,
  Button,
  BackButton,
} from "./styles"
import { Body, Buttons } from "../../steps/shared-styles"
import HeaderProgress from "../../components/HeaderProgress"

const CodeInvite = () => {
  const [code, setCode] = useState("")

  async function submit() {
    const { getDeal } = await client.request(querys.GET_DEAL, {
      input: {
        identification: code.replace('-', '')
      },
    })

    navigate('/deal-invite', { state: { deal: getDeal}})
  }

  return (
    <div>
      <HeaderProgress description="Sobre você" percent={25} />
      <Container padding>
        <Section>
          <Body>
            <Header>
              <Profile>
                <User src={Photo} />
              </Profile>
              <Label><p>Entre em um grupo ou crie o seu próprio grupo:</p></Label>
            </Header>
            <Form>
              <Input
                placeholder="XXX-XXX"
                onChange={({ target }) => setCode(target.value.toUpperCase())}
                mask="***-***"
                maskChar=""
                alwaysShowMask={false}
                value={code}
              ></Input>
            </Form>
          </Body>
          <Buttons>
            <BackButton style={{ textDecoration: "none" }}>
              Criar grupo
            </BackButton>
            <Button disabled={code.length < 7} onClick={submit}>
              Entrar no grupo
            </Button>
          </Buttons>
        </Section>
      </Container>
    </div>
  )
}

export default CodeInvite
